import {
  FooterWrapper,
  FooterInner,
  IconWrapper,
  FooterLabel,
  FooterLabelWrapper,
  PriceStyled,
  ProductName,
  AddToBagButton,
} from './styles'
import { CameraIcon, CartIcon, ShareIcon } from '@components/core/Icons'
import { Product, ProductPrice } from '@customTypes/product'
import { useCallback } from 'react'
import { getTransitionLensLabel } from '@libs/transition'
import { useConfig } from '@providers/configProvider'
import { isDesktopView } from '@libs/utils'
import { useTranslation } from 'react-i18next'
import { useTakePhoto } from '@libs/analytics'
import { useSelector } from 'react-redux'
import { AppState } from '@store/index'
import { useModal } from '@hooks/useModal'
import { useAddToCart } from '@hooks/useAddToCart'
import { ArrowRightIcon } from '@components/core/Icons/ArrowRightIcon'

const Price: React.FC<{ price?: ProductPrice }> = ({ price }) => {
  if (!price) return null

  const { previous, currency, current } = price

  return (
    <>
      {previous && (
        <PriceStyled cross={true}>
          {previous.text}
          {currency}{' '}
        </PriceStyled>
      )}
      <PriceStyled>
        {currency}
        {current.text}
      </PriceStyled>
    </>
  )
}

interface FooterActionsProps {
  className?: string
  product: Product
  getScreenshot?: () => Promise<void>
  isReady: boolean
  isPreparingScreenshot: boolean
  isTransitionActive: boolean
  lensType?: string
  showScreenshotIcon?: boolean
}

export const FooterActions: React.FC<FooterActionsProps> = ({
  className,
  isPreparingScreenshot,
  isReady,
  product,
  getScreenshot,
  isTransitionActive,
  lensType,
  showScreenshotIcon = false,
}) => {
  const { isTakeScreenshotEnabled, addToCartText, onAddPrescriptionLenses, onAddToBag } =
    useConfig()
  const { t } = useTranslation()
  const trackTakePhoto = useTakePhoto(product)
  const activeName = useSelector((s: AppState) => s.ui.activeName)

  const { toggleModalVisibility, isModalVisible } = useModal()
  const { addToCart, currentProductPrice } = useAddToCart({ product })

  const handleAddToCart =
    onAddPrescriptionLenses && product.rxAvailable && !isModalVisible
      ? toggleModalVisibility
      : addToCart

  const takeScreenShot = useCallback(() => {
    if (isReady && !isPreparingScreenshot) {
      getScreenshot && getScreenshot()
      trackTakePhoto()
    }
  }, [getScreenshot, isReady, isPreparingScreenshot, trackTakePhoto])

  const addToBagButton = () => {
    return isDesktopView() ? (
      <AddToBagButton onClick={handleAddToCart}>
        {addToCartText || t('general.add-to-cart')}
      </AddToBagButton>
    ) : (
      <IconWrapper onClick={handleAddToCart}>
        {onAddPrescriptionLenses ? <ArrowRightIcon /> : <CartIcon width="1.5em" height="1.5em" />}
      </IconWrapper>
    )
  }

  const PhotoShareIcon = () => {
    return (
      <IconWrapper onClick={takeScreenShot}>
        {showScreenshotIcon ? (
          <CameraIcon width="2em" height="2em" fill="#fff" />
        ) : (
          <ShareIcon width="1.5em" height="1.5em" fill="#fff" />
        )}
      </IconWrapper>
    )
  }

  if (!isReady || (isTransitionActive && activeName === 'lens')) {
    return null
  }

  const isLeftPhotoShareIcon = !isTransitionActive && isTakeScreenshotEnabled

  return (
    <FooterWrapper className={className}>
      <FooterInner>
        {isLeftPhotoShareIcon && PhotoShareIcon()}
        <FooterLabelWrapper isAlignedLeft={!isLeftPhotoShareIcon}>
          <ProductName>{product.name}</ProductName>
          <FooterLabel>
            {isTransitionActive ? (
              getTransitionLensLabel(lensType)
            ) : (
              <Price price={currentProductPrice} />
            )}
          </FooterLabel>
        </FooterLabelWrapper>
        {isTransitionActive && isTakeScreenshotEnabled && PhotoShareIcon()}
        {!isTransitionActive && onAddToBag && addToBagButton()}
      </FooterInner>
    </FooterWrapper>
  )
}
