import { pxToEm } from '@libs/styled'
import styled from 'styled-components'

export const ScreenDeviceWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.abbey};
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ScreenDeviceShapeWrapper = styled.div`
  position: relative;
  height: calc(${pxToEm(144)} + ${pxToEm(4.8)});
  width: calc(${pxToEm(176)} + ${pxToEm(4.8)});
  margin-bottom: ${pxToEm(16)};
`

export const ScreenDeviceShapeHorizontal = styled.div`
  width: ${pxToEm(144)};
  height: ${pxToEm(64)};
  border: ${pxToEm(2.4)} solid ${({ theme }) => theme.palette.darkGray};
  border-radius: ${pxToEm(8)};
  position: relative;
  top: calc(${pxToEm(80)});
  left: 0;
`

export const ScreenDeviceShapeVertical = styled.div`
  width: ${pxToEm(64)};
  height: ${pxToEm(144)};
  border: ${pxToEm(2.4)} solid ${({ theme }) => theme.palette.white[100]};
  border-radius: ${pxToEm(8)};
  background-color: ${({ theme }) => theme.palette.darkGray};
  position: absolute;
  bottom: 0;
  right: 0;
`

export const ScreenDeviceText = styled.div`
  color: ${({ theme }) => theme.palette.white[100]};
  font-size: ${pxToEm(18)}
  margin: 0;
`

export const ScreenDeviceArrowWrapper = styled.div`
  position: absolute;
  width: ${pxToEm(48)};
  height: ${pxToEm(40)};
  top: ${pxToEm(27.2)};
  left: ${pxToEm(53)};
`

export const IconWrapper = styled.div`
  position: absolute;
  top: ${pxToEm(16)};
  right: 0;
  z-index: 1001;
  padding: ${pxToEm(8)};
  background: ${({ theme }) => theme.palette.lightgray};
`
