export const device = {
  large: '1224px',
  medium: '768px',
  small: '376px',
}

export const theme = {
  palette: {
    lightgray: '#cdcdcd',
    gray: {
      100: 'rgb(148, 148, 148)',
      70: 'rgba(148, 148, 148, .7)',
      50: 'rgba(148, 148, 148, .5)',
      40: 'rgba(148, 148, 148, .4)',
    },
    white: {
      100: 'rgb(255 255 255)',
      90: 'rgba(255, 255, 255, 0.89)',
      70: 'rgba(255, 255, 255, 0.7)',
      50: 'rgba(255, 255, 255, 0.55)',
      40: 'rgba(255, 255, 255, 0.4)',
    },
    black: '#000',
    pinkRed: '#e84159',
    blueberry: '#8f73ff',
    whiteSmoke: '#f6f6f6',
    dodgerblue: 'dodgerblue',
    abbey: '#515c62',
    darkGray: '#a4a4a4',
    silver: '#D0D0D0',
    error: '#c83430',
  },
  media: {
    large: `@media only screen and (min-width: ${device.large})`,
    medium: `@media only screen and (min-width: ${device.medium})`,
    small: `@media only screen and (min-width: ${device.small})`,
  },
  fonts: {
    title: 'bold normal 28px/normal HelveticaNeue',
    titleSmall: 'bold normal 20px/normal HelveticaNeue',
    heading: 'normal normal 500 32px/normal HelveticaNeue-Bold, sans-serif',
    headingSmall: 'normal normal 500 22px/normal HelveticaNeue, sans-serif',
    headingMediumBold: 'normal normal bold 24px/normal HelveticaNeue, sans-serif',
    paragraph: 'normal normal 200 16px/normal HelveticaNeue, sans-serif',
    paragraphBold: 'normal normal bold 16px/normal HelveticaNeue-Bold, sans-serif',
    paragraphSmall: 'normal normal 200 12px/normal HelveticaNeue, sans-serif',
    spanBold: 'normal normal bold 14px/normal HelveticaNeue-Bold, sans-serif',
    span: 'normal normal 200 14px/normal HelveticaNeue, sans-serif',
    buttonText: 'normal normal 200 12px/18px HelveticaNeue-Medium, sans-serif',
    buttonTextLink: 'normal normal 200 12px/16px HelveticaNeue, sans-serif;',
    qrCodeTitle: 'normal normal bold 20px / normal HelveticaNeue;',
  },
}
