import { Screenshot } from '@customTypes/screenshot'
import { useConfig } from '@providers/configProvider'
import { useCallback, useEffect, useState } from 'react'
import { createScreenshotCanvas, IMAGE_QUALITY, IMAGE_TYPE } from '@libs/saveScreenshot'
import { Product } from '@customTypes/product'
import { addAnalyticsQueryParam } from '@libs/analytics'

export const useShare = (product: Product, image?: Screenshot | null) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [imgDataUrl, setImgDataUrl] = useState<string | null>(null)
  const { onShare, store, style } = useConfig()

  const getImgDataUrl = useCallback(async () => {
    if (!image) return
    const screenShot = await createScreenshotCanvas(image, product, style, store)
    const dataUrl = screenShot.toDataURL(IMAGE_TYPE, IMAGE_QUALITY)
    setImgDataUrl(dataUrl)
  }, [image, product, style, store])

  useEffect(() => {
    getImgDataUrl()
  }, [getImgDataUrl])

  const share = async () => {
    if (!navigator.share) {
      setError('Your browser does not support share functionality')
      return
    }

    setLoading(true)
    const url = imgDataUrl && (await onShare?.(imgDataUrl, product))
    setLoading(false)

    if (url) {
      navigator.share({
        url: addAnalyticsQueryParam(url),
      })
    }
  }

  return { share, error, loading }
}
