import { NormalizedLandmarkList } from "@mediapipe/face_mesh";
import { Keypoint } from "../3rdparty/tfjs-models/shared/calculators/interfaces/common_interfaces";

export function keypointsToNormalizedLandmarkList(
  keypoints: Keypoint[]
): NormalizedLandmarkList {
  // const landmarks: NormalizedLandmarkList = [];
  // keypoints.forEach((kp) => {
  //   landmarks.push({
  //     x: kp.x,
  //     y: kp.y,
  //     z: kp.z !== undefined ? kp.z : 0.0,
  //     visibility: kp.score,
  //   });
  // });

  const landmarks: NormalizedLandmarkList = keypoints.map((kp) => {
    return {
      x: kp.x,
      y: kp.y,
      z: kp.z !== undefined ? kp.z : 0.0,
      visibility: kp.score,
    };
  });

  return landmarks;
}

export function normalizedLandmarkListToKepoints(
  landmarks: NormalizedLandmarkList
): Keypoint[] {
  // const keypoints: Keypoint[] = [];
  // landmarks.forEach((kp) => {
  //   keypoints.push({
  //     x: kp.x,
  //     y: kp.y,
  //     z: kp.z,
  //     score: kp.visibility,
  //   });
  // });

  const keypoints: Keypoint[] = landmarks.map((kp) => {
    return {
      x: kp.x,
      y: kp.y,
      z: kp.z,
      score: kp.visibility,
    };
  });

  return keypoints;
}
