import styled, { css } from 'styled-components'
import { pxToEm } from '@libs/styled'
import { Button } from '@components/core/Button'

const textDescription = css`
  z-index: 1;
  margin-top: ${pxToEm(80)};
  font: ${({ theme }) => theme.fonts.paragraph};
`

export const SpinnerWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Spinner = styled.div`
  border: ${pxToEm(4)} solid ${({ theme }) => theme.palette.whiteSmoke};
  border-top: ${pxToEm(4)} solid ${({ theme }) => theme.palette.black};
  border-radius: 50%;
  width: ${pxToEm(80)};
  height: ${pxToEm(80)};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const FaceScanAppWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .navigation,
  .page-title,
  .frame-advisor-error-overlay .frame-advisor-error-overlay-modal-button {
    display: none;
  }

  .frame-advisor-root-style .fa-time-remaining {
    position: absolute;
    font-family: HelveticaNeue;
    font-size: ${pxToEm(104)};
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: none;
  }

  .frame-advisor-error-overlay .frame-advisor-error-overlay-modal {
    height: ${pxToEm(150)};
  }

  .frame-advisor-root-style .fa-nudging-guide {
    ${textDescription}
    height: unset;
    text-shadow: none;
  }

  video::-webkit-media-controls {
    display: none;
  }
`

export const ParagraphDescription = styled.p`
  ${textDescription}
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.palette.white[100]};
`

export const BlurBlock = styled.div`
  filter: blur(15px);
  border: solid ${pxToEm(11)} rgba(33, 92, 207, 0.6);
  border-radius: ${pxToEm(30)};
  position: absolute;
  width: 80%;
  height: 50%;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  ${({ theme }) => theme.media.medium} {
    height: 60%;
    width: 60%;
  }
`

export const Corner = styled.div<{ scanSuccess: boolean }>`
  width: ${({ scanSuccess }) => (scanSuccess ? '100%' : '40%')};
  height: ${({ scanSuccess }) => (scanSuccess ? '100%' : '40%')};
  border: ${pxToEm(4)} solid white;
  border-radius: ${pxToEm(30)};
  ${({ scanSuccess }) =>
    !scanSuccess &&
    `
      border-bottom: 0;
      border-right: 0;
      border-radius: 0;
      border-top-left-radius: ${pxToEm(30)};
  `}

  &.vmmv-top-right {
    transform: scale(-1, 1);
  }

  &.vmmv-bottom-left {
    transform: scale(1, -1);
  }

  &.vmmv-bottom-right {
    transform: rotate(180deg);
  }
`

export const FaceScanAppTarget = styled.div`
  border-radius: ${pxToEm(30)};
  height: 50%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 1;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ theme }) => theme.media.medium} {
    height: 60%;
    width: 60%;
  }
`

export const GradientBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #000c13 0%, rgba(196, 196, 196, 0) 100%);
  opacity: 0.6;
`

export const GradientBackgroundPolygon = styled(GradientBackground)`
  clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(10% + 2px) 100%,
    calc(10% + 2px) calc(28% + 2px),
    calc(14% + 2px) calc(25% + 2px),
    calc(86% - 2px) calc(25% + 2px),
    calc(90% - 2px) calc(28% + 2px),
    calc(90% - 2px) calc(72% - 2px),
    calc(86% - 2px) calc(75% - 2px),
    calc(14% + 2px) calc(75% - 2px),
    calc(10% + 2px) calc(72% - 2px),
    calc(10% + 2px) 100%,
    100% 100%,
    100% 0%
  );

  ${({ theme }) => theme.media.medium} {
    clip-path: polygon(
      0% 0%,
      0% 100%,
      calc(20% + 2px) 100%,
      calc(20% + 2px) calc(22% + 2px),
      calc(22% + 2px) calc(20% + 2px),
      calc(78% - 2px) calc(20% + 2px),
      calc(80% - 2px) calc(22% + 2px),
      calc(80% - 2px) calc(78% - 2px),
      calc(78% - 2px) calc(80% - 2px),
      calc(22% + 2px) calc(80% - 2px),
      calc(20% + 2px) calc(78% - 2px),
      calc(20% + 2px) 100%,
      100% 100%,
      100% 0%
    );
  }
`

export const FaceScanAppContainer = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-content: center;

  ellipse {
    stroke: transparent;
  }
`

export const TakePictureButton = styled(Button)`
  background: ${({ theme }) => theme.palette.black};
  padding: ${pxToEm(20)} ${pxToEm(40)};
  height: unset;
  width: unset;
  margin-top: ${pxToEm(32)};

  ${({ theme }) => theme.media.medium} {
    margin-top: ${pxToEm(120)};
  }

  ${({ theme }) => theme.media.large} {
    margin-top: ${pxToEm(24)};
  }
`
