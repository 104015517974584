import { OneEuroFilter } from "../3rdparty/tfjs-models/shared/filters/one_euro_filter";
import { RelativeVelocityFilter } from "../3rdparty/tfjs-models/shared/filters/relative_velocity_filter";
import { Euler } from "../3rdparty/threejs-math/Euler";
import { RAD2DEG } from "../3rdparty/threejs-math/MathUtils";
import { Matrix4 } from "../3rdparty/threejs-math/Matrix4";
import { Quaternion } from "../3rdparty/threejs-math/Quaternion";
import { Vector3 } from "../3rdparty/threejs-math/Vector3";
import { KalmanFilter1D, KalmanFilterConfig } from "../KalmanFilter";
import {
  OneEuroFilterConfig,
  VelocityFilterConfig,
} from "../3rdparty/tfjs-models/shared/calculators/interfaces/config_interfaces";

type Filter = OneEuroFilter | RelativeVelocityFilter | KalmanFilter1D;

export interface PoseSmoothingFilterConfig {
  oneEuroFilter?: OneEuroFilterConfig;
  velocityFilter?: VelocityFilterConfig;
  kalmanFilter?: KalmanFilterConfig;
}

function poseSmootherConfigToDict(config: PoseSmoothingFilterConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const d = new Map<string, any>();
  d.set("oneEuroFilter", config.oneEuroFilter);
  d.set("velocityFilter", config.velocityFilter);
  d.set("kalmanFilter", config.kalmanFilter);
  return d;
}

const computeRadialYawDeg = (p: Vector3, e: Euler): number => {
  const positionYaw = -Math.atan2(p.x, -p.z) * RAD2DEG;
  const poseYaw = e.y * RAD2DEG;
  const yawDiff = positionYaw - poseYaw;
  /*
  console.log(
    `positionYaw: ${positionYaw.toFixed(2)},` +
      `poseYaw: ${poseYaw.toFixed(2)}, ` +
      `yawDiff: ${yawDiff.toFixed(2)}`
  );
  */
  return yawDiff;
};

export class PoseSmoothingCalculator {
  private m_translationSmoothers: Filter[] = [];

  private m_rotationSmoothers: Filter[] = [];

  private m_translationConfig: PoseSmoothingFilterConfig;

  private m_rotationConfig: PoseSmoothingFilterConfig;

  private m_attachAngleDeg: number;

  private m_attached: boolean;

  constructor(
    translationConfig: PoseSmoothingFilterConfig,
    rotationConfig: PoseSmoothingFilterConfig,
    attachAngleDeg: number
  ) {
    this.m_translationConfig = translationConfig;
    this.m_rotationConfig = rotationConfig;
    this.m_attachAngleDeg = attachAngleDeg;
    this.m_attached = false;
    this.reset();
  }

  get translationConfig(): PoseSmoothingFilterConfig {
    return this.m_translationConfig;
  }

  set translationConfig(c: PoseSmoothingFilterConfig) {
    this.m_translationConfig = c;
    this.reset();
  }

  get rotationConfig(): PoseSmoothingFilterConfig {
    return this.m_rotationConfig;
  }

  set rotationConfig(c: PoseSmoothingFilterConfig) {
    this.m_rotationConfig = c;
    this.reset();
  }

  get attachAngleDeg() {
    return this.m_attachAngleDeg;
  }

  set attachAngleDeg(a: number) {
    this.m_attachAngleDeg = a;
  }

  apply(poseMatrix: number[][], timestamp: number): number[][] {
    // check if the matrix is 4x4
    const is4x4 = poseMatrix.reduce((previousResult, row) => {
      return row.length === 4 && previousResult;
    }, poseMatrix.length === 4);
    if (!is4x4) {
      throw new Error("Error in pose filter, not a 4x4 matrix");
    }

    const m = new Matrix4();
    m.set(
      /* eslint-disable prettier/prettier */
      poseMatrix[0][0], poseMatrix[0][1], poseMatrix[0][2], poseMatrix[0][3], 
      poseMatrix[1][0], poseMatrix[1][1], poseMatrix[1][2], poseMatrix[1][3], 
      poseMatrix[2][0], poseMatrix[2][1], poseMatrix[2][2], poseMatrix[2][3], 
      poseMatrix[3][0], poseMatrix[3][1], poseMatrix[3][2], poseMatrix[3][3]
      /* eslint-enable prettier/prettier */
    );
    const p = new Vector3();
    const q = new Quaternion();
    const s = new Vector3();
    m.decompose(p, q, s);
    const e = new Euler();
    e.setFromQuaternion(q, "XYZ");

    const radialYawDeg = computeRadialYawDeg(p, e);

    /*
    console.log(
      `radialYawDeg: ${radialYawDeg.toFixed(2)}°, ` +
        `attachAngleDeg: ${this.m_attachAngleDeg?.toFixed(2)}, ` +
        `attached: ${this.m_attached}`
    );
    */

    if (
      this.m_attachAngleDeg !== undefined &&
      Math.abs(radialYawDeg) <= this.m_attachAngleDeg
    ) {
      // console.log("No smooth, detach");
      // No smoothing required, just return original data
      this.m_attached = false;
      return poseMatrix;
    }

    // Smoothing required! But first check if we wasn't attached yet, in that case filters need to be reset before tracking
    if (!this.m_attached) {
      // console.log("Smooth but detached, reset filters");
      this.reset();
    }
    // console.log("Smooth");
    this.m_attached = true;

    // Do the smoothing
    const translationValueScale = 1.0;
    [p.x, p.y, p.z] = [p.x, p.y, p.z].map((v, i) =>
      this.m_translationSmoothers[i].apply(v, timestamp, translationValueScale)
    );

    const rotationValueScale = 1.0;
    [e.x, e.y, e.z] = [e.x, e.y, e.z].map((v, i) =>
      this.m_rotationSmoothers[i].apply(v, timestamp, rotationValueScale)
    );

    // Recompose pose matrix
    q.setFromEuler(e);
    m.compose(p, q, s);

    const a = m.elements;
    // Matrix4 is column-major but we work in row-major, so transpose it while returning
    const outputPoseMatrix = [
      [a[0], a[4], a[8], a[12]],
      [a[1], a[5], a[9], a[13]],
      [a[2], a[6], a[10], a[14]],
      [a[3], a[7], a[11], a[15]],
    ];

    return outputPoseMatrix;
  }

  reset() {
    if (this.m_translationConfig.oneEuroFilter !== undefined) {
      this.m_translationSmoothers = [
        new OneEuroFilter(this.m_translationConfig.oneEuroFilter),
        new OneEuroFilter(this.m_translationConfig.oneEuroFilter),
        new OneEuroFilter(this.m_translationConfig.oneEuroFilter),
      ];
    } else if (this.m_translationConfig.velocityFilter !== undefined) {
      this.m_translationSmoothers = [
        new RelativeVelocityFilter(this.m_translationConfig.velocityFilter),
        new RelativeVelocityFilter(this.m_translationConfig.velocityFilter),
        new RelativeVelocityFilter(this.m_translationConfig.velocityFilter),
      ];
    } else if (this.m_translationConfig.kalmanFilter !== undefined) {
      this.m_translationSmoothers = [
        new KalmanFilter1D(this.m_translationConfig.kalmanFilter),
        new KalmanFilter1D(this.m_translationConfig.kalmanFilter),
        new KalmanFilter1D(this.m_translationConfig.kalmanFilter),
      ];
    }

    if (this.m_rotationConfig.oneEuroFilter !== undefined) {
      this.m_rotationSmoothers = [
        new OneEuroFilter(this.m_rotationConfig.oneEuroFilter),
        new OneEuroFilter(this.m_rotationConfig.oneEuroFilter),
        new OneEuroFilter(this.m_rotationConfig.oneEuroFilter),
      ];
    } else if (this.m_rotationConfig.velocityFilter !== undefined) {
      this.m_rotationSmoothers = [
        new RelativeVelocityFilter(this.m_rotationConfig.velocityFilter),
        new RelativeVelocityFilter(this.m_rotationConfig.velocityFilter),
        new RelativeVelocityFilter(this.m_rotationConfig.velocityFilter),
      ];
    } else if (this.m_rotationConfig.kalmanFilter !== undefined) {
      this.m_rotationSmoothers = [
        new KalmanFilter1D(this.m_rotationConfig.kalmanFilter),
        new KalmanFilter1D(this.m_rotationConfig.kalmanFilter),
        new KalmanFilter1D(this.m_rotationConfig.kalmanFilter),
      ];
    }
  }

  toDict() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d = new Map<string, any>();
    d.set(
      "translationConfig",
      poseSmootherConfigToDict(this.m_translationConfig)
    );
    d.set("rotationConfig", poseSmootherConfigToDict(this.m_rotationConfig));
    d.set("attachAngleDeg", this.m_attachAngleDeg);
    return d;
  }
}
