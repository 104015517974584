import { NormalizedLandmarkList } from "@mediapipe/face_mesh";
import { ISize, IPoint, IPointList } from "./types";

export class Point2D implements IPoint {
  x: number;

  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  scale(size: ISize): IPoint {
    this.x *= size.width;
    this.y *= size.height;
    return this;
  }

  translate(tr: IPoint): IPoint {
    this.x += tr.x;
    this.y += tr.y;
    return this;
  }

  scaled(size: ISize): IPoint {
    const p: Point2D = this.clone();
    p.scale(size);
    return p;
  }

  translated(tr: IPoint): IPoint {
    const p: Point2D = this.clone();
    p.translate(tr);
    return p;
  }

  clone(): Point2D {
    return new Point2D(this.x, this.y);
  }

  static create(p: IPoint): Point2D {
    return new Point2D(p.x, p.y);
  }
}

export function convertedToPointList(
  landmarks: NormalizedLandmarkList,
  filter?: number[],
  offset?: number
): IPointList {
  if (filter !== undefined && filter.length > 0) {
    const off = offset !== undefined ? offset : 0;
    return filter.map((i) => Point2D.create(landmarks[i + off]));
  }
  return landmarks.map((mark) => new Point2D(mark.x, mark.y));
}

export function scaledPointList(pts: IPointList, size: ISize): IPointList {
  return pts.map((p) => Point2D.create(p).scaled(size));
}

export function translatedPointList(pts: IPointList, tr: IPoint): IPointList {
  return pts.map((p) => Point2D.create(p).translated(tr));
}

export function toArrayList(pts: IPointList): number[][] {
  return pts.map((p) => [p.x, p.y]);
}

export function pointsDistance(a: IPoint, b: IPoint): number {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return Math.sqrt(dx * dx + dy * dy);
}
