import { Options } from "@mediapipe/face_mesh";
import {
  KeypointsSmoothingConfig,
  OneEuroFilterConfig,
  VelocityFilterConfig,
} from "./3rdparty/tfjs-models/shared/calculators/interfaces/config_interfaces";
import { KalmanFilterConfig } from "./KalmanFilter";

export function faceMeshOptionsToDict(options: Options) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const d = new Map<string, any>();
  d.set("cameraNear", options.cameraNear);
  d.set("cameraFar", options.cameraFar);
  d.set("cameraVerticalFovDegrees", options.cameraVerticalFovDegrees);
  d.set("enableFaceGeometry", options.enableFaceGeometry);
  d.set("selfieMode", options.selfieMode);
  d.set("maxNumFaces", options.maxNumFaces);
  d.set("refineLandmarks", options.refineLandmarks);
  d.set("minDetectionConfidence", options.minDetectionConfidence);
  d.set("minTrackingConfidence", options.minTrackingConfidence);
  return d;
}

export function oneEuroFilterConfigToDict(config?: OneEuroFilterConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const d = new Map<string, any>();
  d.set("frequency", config?.frequency);
  d.set("minCutOff", config?.minCutOff);
  d.set("beta", config?.beta);
  d.set("derivateCutOff", config?.derivateCutOff);
  d.set("thresholdCutOff", config?.thresholdCutOff);
  d.set("thresholdBeta", config?.thresholdBeta);
  d.set("minAllowedObjectScale", config?.minAllowedObjectScale);
  d.set("disableValueScaling", config?.disableValueScaling);
  return d;
}

export function velocityFilterConfigToDict(config?: VelocityFilterConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const d = new Map<string, any>();
  d.set("windowSize", config?.windowSize);
  d.set("velocityScale", config?.velocityScale);
  d.set("minAllowedObjectScale", config?.minAllowedObjectScale);
  d.set("disableValueScaling", config?.disableValueScaling);
  return d;
}

export function kalmanFilterConfigToDict(config: KalmanFilterConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const d = new Map<string, any>();
  d.set("C", config.C);
  d.set("varQ", config.varQ);
  d.set("varR", config.varR);
  return d;
}

export function keypointSmoothingConfigToDict(
  config: KeypointsSmoothingConfig
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const d = new Map<string, any>();
  d.set("velocityFilter", velocityFilterConfigToDict(config.velocityFilter));
  d.set("oneEuroFilter", oneEuroFilterConfigToDict(config.oneEuroFilter));
  return d;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapToObject(m: Map<string, any>) {
  const obj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [k, v] of m) {
    if (v instanceof Map) {
      Object.defineProperty(obj, k, {
        value: mapToObject(v),
        enumerable: true,
      });
    } else {
      Object.defineProperty(obj, k, {
        value: v,
        enumerable: true,
      });
    }
  }
  return obj;
}
