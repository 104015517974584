import { Product, Brand } from '@customTypes/product'
import { Store } from '@customTypes/store'
import { Screenshot } from '@customTypes/screenshot'
import { getColorFromStyle } from './getColorFromStyle'
import { getFontFromStyle } from './getFontFromStyle'
import { isMobileView, isSmallMobileView } from './utils'

import rb from '../assets/rb-logo.svg'
import mk from '../assets/mk-logo.svg'
import oo from '../assets/oo-logo.svg'
import opsm from '../assets/opsm-logo.svg'
import sgh from '../assets/sgh-logo.svg'
import sv from '../assets/sv-logo.svg'
import to from '../assets/to-logo.svg'

export const getDefaultLogoPath = (code: string): string => {
  const logos: Record<string, string> = {
    rb,
    mk,
    oo,
    opsm,
    sgh,
    sv,
    to,
  }
  return logos[code]
}

export const getNoCacheBrandUrl = (style: string, brand: Brand, standAlone?: boolean) => {
  return ['sv', 'rb', 'sgh', 'oo', 'to', 'mk', 'opsm', 'lc'].includes(style) && !standAlone
    ? getDefaultLogoPath(style)
    : brand.logoUrl
}

export const IMAGE_TYPE = 'image/jpeg'
export const IMAGE_QUALITY = 0.75

const createImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise(function (resolve, reject) {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

type Line = {
  content: string
  x: number
  y: number
}

function wrapText(
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number
) {
  let lines: Line[] = []
  let words = text.split(' '),
    line = ''

  for (let i = 0; i < words.length; i++) {
    let test = words[i]
    let metrics = context.measureText(test)

    while (metrics.width > maxWidth) {
      // Determine how much of the word will fit
      test = test.substring(0, test.length - 1)
      metrics = context.measureText(test)
    }
    if (words[i] != test) {
      words.splice(i + 1, 0, words[i].substr(test.length))
      words[i] = test
    }

    test = line + words[i] + ' '
    metrics = context.measureText(test)

    if (metrics.width > maxWidth && i > 0) {
      lines = lines.concat({ content: line, x, y })
      line = words[i] + ' '
      y += lineHeight
    } else {
      line = test
    }
  }
  lines = lines.concat({ content: line, x, y })
  return lines
}

export const createScreenshotCanvas = async (
  screenshot: Screenshot,
  product: Product,
  style: string,
  store?: Store
): Promise<HTMLCanvasElement> => {
  const { thumbnailUrl, brand, name } = product
  const font = getFontFromStyle(style)
  const { infoColor } = getColorFromStyle(style)
  const standAloneStore = store && store.standAlone
  const hasTemplateStyle = store && store.logo
  const noCacheBrandUrl = getNoCacheBrandUrl(style, brand, standAloneStore)
  const brandLogoUrl = hasTemplateStyle && store.logo ? store.logo : noCacheBrandUrl

  const [brandLogoEl, thumbnailEl, screenshotEl] = await Promise.all(
    [brandLogoUrl, thumbnailUrl, screenshot.imgURI].map(createImage)
  )

  const baseWidth = screenshotEl.width
  const canvasW = baseWidth
  const canvasH = screenshotEl.height
  const screenshotX = 0
  const screenshotY = 0
  const screenshotW = canvasW
  const screenshotH = canvasH

  // ******** CONSTANTS ******** //
  const padding = isMobileView() ? 16 : 24
  const modelNamePadding = isSmallMobileView() ? padding * 1.5 : padding * 2

  // wrapper
  const realWrapperWidth = baseWidth - padding * 2
  const realWrapperHeight = canvasH * 0.2

  const textH = realWrapperHeight * 0.16

  const cvs = document.createElement('canvas')

  cvs.width = canvasW
  cvs.height = canvasH

  const ctx = cvs.getContext('2d')

  if (ctx === null) throw new Error('2d canvas context non supported')

  ctx.textBaseline = 'top'

  // Background
  ctx.fillStyle = 'white'

  ctx.fillRect(0, 0, canvasW, canvasH)
  ctx.drawImage(screenshotEl, screenshotX, screenshotY, screenshotW, screenshotH)

  const modelNameFontSize = realWrapperHeight * 0.12 // safari doesn't support "rem" font in canvas
  const modelInfoFontSize = realWrapperHeight * 0.1

  // Brand Logo
  const brandWidth = realWrapperWidth / 6
  const brandHeight = realWrapperHeight / 4
  const brandRatio = brandLogoEl.width / brandLogoEl.height
  let brandDrawWidth = brandRatio > 1 ? brandWidth : brandHeight * brandRatio
  let brandDrawHeight = brandRatio <= 1 ? brandHeight : brandWidth / brandRatio

  // LENS
  const lensRatio = thumbnailEl.width / thumbnailEl.height
  const lensWidth = realWrapperWidth / 2
  const lensHeight = realWrapperHeight / 2
  let lensDrawWidth = lensRatio > 1 ? lensWidth : lensHeight * lensRatio
  let lensDrawHeight = lensRatio <= 1 ? lensHeight : lensWidth / lensRatio

  if (brandDrawHeight > brandHeight) {
    brandDrawWidth = brandHeight * brandRatio
    brandDrawHeight = brandHeight
  }

  if (lensDrawHeight > lensHeight) {
    lensDrawWidth = lensHeight * lensRatio
    lensDrawHeight = lensHeight
  }

  const wrapperWidth = canvasW
  const wrapperContentRightPosition =
    wrapperWidth - lensDrawWidth + (lensDrawWidth - brandDrawWidth) / 2
  const wrapperContentRightPositionLens = wrapperWidth - lensDrawWidth

  // model name text lines
  let modelNameLines = wrapText(ctx, name + ' ', 0, 0, (baseWidth - padding * 2) / 2.8, textH)

  if (modelNameLines.length === 0) {
    modelNameLines = [{ content: '', x: 0, y: 0 }]
  }

  // lens text lines
  const lensInfoText = `${product.brand.name}\n${
    product.frameColorLabel ? product.frameColorLabel?.replace(';', ' ') + '\n' : ''
  }${product.lensColorLabel ? product.lensColorLabel : ''}`
  const textLines = lensInfoText.split('\n')

  let subtextLens: Line[] = []
  for (let i = 0; i < textLines.length; ++i) {
    subtextLens = subtextLens.concat(
      wrapText(ctx, textLines[i], 0, 0, (baseWidth - padding * 2) / 2.8, textH)
    )
  }

  const wrapperHeight = realWrapperHeight

  const wrapperContentTopPositionLens = canvasH - wrapperHeight + wrapperHeight / 2 - padding

  const wrapperContentTopPositionLensText =
    canvasH - wrapperHeight + modelNamePadding + modelNameLines.length * textH

  // ******** LENS BOTTOM WRAPPER ******** //
  const gradient = ctx.createRadialGradient(
    canvasW / 2,
    canvasH - wrapperHeight - padding,
    0,
    canvasW / 2,
    canvasH - wrapperHeight - padding,
    250
  )

  gradient.addColorStop(0, 'rgba(255, 255, 255, 0.55)')
  gradient.addColorStop(1, 'rgba(255, 255, 255, 0.89)')

  ctx.fillStyle = gradient
  ctx.fillRect(0, canvasH - wrapperHeight, canvasW, wrapperHeight)

  const contentX = padding * 1

  const subTextLensMap = subtextLens.map(({ content }) => content)

  for (let i = 0; i < subTextLensMap.length; ++i) {
    let h = textH * i

    ctx.fillStyle = infoColor
    ctx.font = `500 ${modelInfoFontSize}px ${font}`

    ctx.fillText(subTextLensMap[i], contentX, wrapperContentTopPositionLensText + h)
  }

  const modelNameY = canvasH - wrapperHeight + padding

  ctx.font = `bold ${modelNameFontSize}px ${font}`
  for (let i = 0; i < modelNameLines.length; ++i) {
    let h = textH * i

    ctx.fillStyle = infoColor

    ctx.fillText(modelNameLines[i].content, contentX, modelNameY + h)
  }

  // ******** BRAND IMAGE ******** //

  ctx.drawImage(
    brandLogoEl,
    wrapperContentRightPosition,
    canvasH - wrapperHeight + padding,
    brandDrawWidth,
    brandDrawHeight
  )

  // ******** LENS IMAGE ******** //

  ctx.drawImage(
    thumbnailEl,
    wrapperContentRightPositionLens,
    wrapperContentTopPositionLens,
    lensDrawWidth,
    lensDrawHeight
  )

  return cvs
}

const downloadCanvas = (screenshotCanvas: HTMLCanvasElement, product: Product) => {
  const dataUrl = screenshotCanvas.toDataURL(IMAGE_TYPE, IMAGE_QUALITY)
  const anchor = document.createElement('a')
  const filename = `${product.brand.name}_${product.name}`.replace(/[^a-z0-9]/gi, '_').toLowerCase()
  anchor.download = `${filename}.jpg`
  anchor.href = dataUrl
  anchor.style.visibility = 'hidden'
  document.body.appendChild(anchor)
  anchor.click()
  setTimeout(() => {
    document.body.removeChild(anchor)
  }, 0)
}

export const saveScreenshot = async (
  screenshot: Screenshot,
  product: Product,
  style: string,
  store?: Store
): Promise<void> => {
  if (!product) throw new Error('UPC not found')

  const screenshotCanvas = await createScreenshotCanvas(screenshot, product, style, store)
  downloadCanvas(screenshotCanvas, product)
}
