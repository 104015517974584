import { BipaModal } from '@components/PrivacyPolicy/BipaModal'
import { LAYOUT_ID } from '@hooks/useDimensions'
import { OrientationSreen } from '@components/OrientationScreen'
import { PrivacyPolicyModal } from '@components/PrivacyPolicy/PrivacyPolicyModal'
import { TermsConditionsModal } from '@components/PrivacyPolicy/TermsAndConditionsModal'
import { normalize } from './normalize'
import { pxToEm } from '@libs/styled'
import styled from 'styled-components'
import React from 'react'

const LayoutStyled = styled.div`
  font-size: 16px;
  height: 100%;
  position: relative;
  overflow: hidden;
  ${normalize}

  ${({ theme }) => theme.media.large} {
    max-width: ${pxToEm(768)};
    margin: 0 auto;
  }
`
interface LayoutProps {
  children: React.ReactNode
}

export const Layout = React.forwardRef(
  ({ children }: LayoutProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <>
        <LayoutStyled id={LAYOUT_ID} ref={ref} data-analytics_available_call="0">
          {children}
          <TermsConditionsModal />
          <PrivacyPolicyModal />
          <BipaModal />
        </LayoutStyled>
        <OrientationSreen />
      </>
    )
  }
)

Layout.displayName = 'Layout'
