import * as math from "mathjs";

export class StaticBuffer {
  m_size: number;

  m_buffer: Array<number> = [];

  constructor(size: number) {
    this.m_size = size;
  }

  get nSamples(): number {
    return this.m_buffer.length;
  }

  get size(): number {
    return this.m_size;
  }

  set size(size: number) {
    this.m_size = math.round(size);
    this.keepUpTo(this.m_size);
  }

  private keepUpTo(n: number) {
    if (this.m_buffer.length > n) {
      // remove from start
      const idx = this.m_buffer.length - n;
      this.m_buffer.splice(0, idx);
    }
  }

  push(sample: number) {
    if (!this.isFilled()) {
      this.m_buffer.push(sample);
    }
  }

  mean(): number {
    if (this.m_buffer.length === 0) {
      return 0;
    }
    return math.mean(this.m_buffer);
  }

  median(): number {
    if (this.m_buffer.length === 0) {
      return 0;
    }
    return math.median(this.m_buffer);
  }

  reset() {
    this.m_buffer = [];
  }

  isFilled() {
    return this.m_size === this.m_buffer.length;
  }
}
