import {
  FaceMesh,
  InputMap,
  matrixDataToMatrix,
  NormalizedLandmarkList,
  Options,
  Results,
  VERSION as mpVersion,
} from "@mediapipe/face_mesh";
import cv from "@techstark/opencv-js";
import * as math from "mathjs";
import { SmartRuler, SmartRulerConfig } from "./smartRuler";
import {
  ProminentSubjectSegmenterResult,
  ProminentSubjectSegmenter,
} from "./prominentSubjectSegmenter";
import { canonicalMetricLandmarks, mpPointsIdx } from "./data";
import {
  createFaceRegion,
  createPupilRegion,
  FB_INDICES,
  FB_INDICES2,
  LEFT_IRIS_INDICES,
  LEFT_LOWER_EYEBROW_INDICES,
  LEFT_UPPER_EYEBROW_INDICES,
  LOWER_INNER_LIPS_INDICES,
  LOWER_OUTER_LIPS_INDICES,
  RIGHT_IRIS_INDICES,
  RIGHT_LOWER_EYEBROW_INDICES,
  RIGHT_UPPER_EYEBROW_INDICES,
  RulerStatus,
  UPPER_INNER_LIPS_INDICES,
  UPPER_OUTER_LIPS_INDICES,
} from "./faceRegions";
import { convertedToPointList, scaledPointList, toArrayList } from "./point";
import { PoseTrackerResult } from "./poseTrackerResult";
import {
  PoseSmoothingCalculator,
  PoseSmoothingFilterConfig,
} from "./calculators/poseSmoothingCalculator";
import { ISize } from "./types";
import { VelocityFilterConfig } from "./3rdparty/tfjs-models/shared/calculators/interfaces/config_interfaces";
import { LandmarksSmoothingCalculator } from "./calculators/landmarksSmoothingCalculator";
import {
  MILLISECOND_TO_MICRO_SECONDS,
  SECOND_TO_MICRO_SECONDS,
} from "./3rdparty/tfjs-models/shared/calculators/constants";
import { Ruler, RulerConfig } from "./ruler";
import { faceMeshOptionsToDict, mapToObject } from "./to_dict";

export type PoseTrackerConfig = {
  verticalFovDeg: number;
  assetsDir?: string;
  fps?: number;
  smartRulerConfig?: SmartRulerConfig;
};

function poseTrackerConfigToDict(config: PoseTrackerConfig) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const d = new Map<string, any>();
  d.set("verticalFovDeg", config.verticalFovDeg);
  d.set("assetsDir", config.assetsDir);
  d.set("fps", config.fps);
  return d;
}

function convertMpPoseToVtoPose(mpPose: number[][]): number[][] {
  const transformationVtoToMp = [
    [9.99977368e-2, -2.47592865e-5, -6.72331321e-4, 5.87963437e-2],
    [3.10721702e-5, 9.99955864e-2, 9.39012215e-4, -8.09490546e-1],
    [6.72069154e-4, -9.39199871e-4, 9.99933309e-2, -1.02301921],
    [0.0, 0.0, 0.0, 1.0],
  ];
  return math.multiply(mpPose, transformationVtoToMp);
}

function rotationMatrixToEulerAnglesXYZ(
  R: number[][]
): [number, number, number] {
  // Implementation based on: https://github.com/luxdeepblue/vto-projects/blob/develop/modules/utils/src/math/angles.cpp
  const sy = Math.sqrt(R[0][0] * R[0][0] + R[1][0] * R[1][0]);
  const singular = sy < 1e-6;

  let x;
  let y;
  let z;

  if (!singular) {
    x = Math.atan2(R[2][1], R[2][2]);
    y = Math.atan2(-R[2][0], sy);
    z = Math.atan2(R[1][0], R[0][0]);
  } else {
    x = Math.atan2(-R[1][2], R[1][1]);
    y = Math.atan2(-R[2][0], sy);
    z = 0;
  }

  return [x, y, z];
}

function convertVtoPoseMatrixToVtoPoseRTS(M: number[][]): {
  r: [number, number, number];
  t: [number, number, number];
  s: number;
} {
  const sVector = [
    Math.sqrt(M[0][0] * M[0][0] + M[1][0] * M[1][0] + M[2][0] * M[2][0]), // scaling x
    Math.sqrt(M[0][1] * M[0][1] + M[1][1] * M[1][1] + M[2][1] * M[2][1]), // scaling y
    Math.sqrt(M[0][2] * M[0][2] + M[1][2] * M[1][2] + M[2][2] * M[2][2]), // scaling z
  ];
  const s = (sVector[0] + sVector[1] + sVector[2]) / 3.0; // Compute scaling as a mean of each component

  const r = rotationMatrixToEulerAnglesXYZ(M);
  const t = [M[0][3], M[1][3], M[2][3]];

  // Last two components of both r and t are expected to be inverted
  return {
    r: [r[0], -r[1], -r[2]],
    t: [t[0], -t[1], -t[2]],
    s,
  };
}

function convertVtoPoseMatrixToVtoPoseArray(
  M: number[][]
): [number, number, number, number, number, number] {
  const RTS = convertVtoPoseMatrixToVtoPoseRTS(M);
  const scaledT = [RTS.t[0] / RTS.s, RTS.t[1] / RTS.s, RTS.t[2] / RTS.s];
  return [RTS.r[0], RTS.r[1], RTS.r[2], scaledT[0], scaledT[1], scaledT[2]];
}

function getPnpPose(
  landmarks: NormalizedLandmarkList,
  imageSize: ISize,
  verticalFovDeg: number,
  pointsIdx: number[]
): number[][] {
  const metricLandmarks = canonicalMetricLandmarks;

  const faceLandmarks = toArrayList(
    scaledPointList(convertedToPointList(landmarks), imageSize)
  );

  const landmarks3d = pointsIdx.map((idx) => metricLandmarks[idx]);
  const landmarks2d = pointsIdx.map((idx) => faceLandmarks[idx]);

  const objectPoints = cv.matFromArray(
    landmarks3d.length,
    3,
    cv.CV_64FC1,
    landmarks3d.flat()
  );

  const imagePoints = cv.matFromArray(
    landmarks2d.length,
    2,
    cv.CV_64FC1,
    landmarks2d.flat()
  );

  const vfovRad = (verticalFovDeg * Math.PI) / 180.0;
  const focal = (0.5 * imageSize.height) / Math.tan(0.5 * vfovRad);
  const cameraIntrinsics = [
    focal,
    0,
    imageSize.width / 2,
    0,
    focal,
    imageSize.height / 2,
    0,
    0,
    1,
  ];

  const cameraMatrix = cv.matFromArray(
    3,
    3,
    cv.CV_64FC1,
    cameraIntrinsics.flat()
  );

  const distCoeff = cv.Mat.zeros(1, 4, cv.CV_64FC1);
  const rvec = cv.Mat.zeros(3, 1, cv.CV_64FC1);
  const tvec = cv.Mat.zeros(3, 1, cv.CV_64FC1);
  cv.solvePnP(
    objectPoints,
    imagePoints,
    cameraMatrix,
    distCoeff,
    rvec,
    tvec,
    false,
    cv.SOLVEPNP_ITERATIVE
  );

  const R = cv.Mat.zeros(3, 3, cv.CV_64FC1);
  cv.Rodrigues(rvec, R);

  const r = R.data64F;
  const t = tvec.data64F;
  const Rt = [
    [r[0], r[1], r[2], t[0]],
    [-r[3], -r[4], -r[5], -t[1]],
    [-r[6], -r[7], -r[8], -t[2]],
    [0, 0, 0, 1],
  ];

  return Rt;
}

export type PoseTrackerResultsListener = (
  results: PoseTrackerResult
) => Promise<void> | void;

function extractRulerConfig(
  config?: SmartRulerConfig
): RulerConfig | undefined {
  if (config) {
    return {
      irisDiameterMM: config.irisDiameterMM,
      historySize: config.maxValidSamples,
    };
  }
  return undefined;
}

export class PoseTracker {
  public usePoseSmoothing: boolean;

  public useLandmarksSmoothing: boolean;

  public enableProminentSubjectSegmenter: boolean;

  private m_face_mesh: FaceMesh;

  private m_facemesh_options: Options;

  private m_initializedFaceMesh: boolean;

  private m_initializedProminentSubject: boolean;

  private m_listener: PoseTrackerResultsListener | undefined;

  private m_poseSmoother: PoseSmoothingCalculator;

  private m_usePosePnP: boolean;

  private m_verticalFovDeg: number;

  private m_prominentSubjectSegmenter?: ProminentSubjectSegmenter;

  private m_landmarksSmoother: LandmarksSmoothingCalculator;

  private m_config: PoseTrackerConfig;

  private m_ruler?: Ruler;

  private m_ruler2?: Ruler;

  private m_smartRuler?: SmartRuler;

  private m_fps?: number;

  private m_timestampMicroseconds: number;

  public selectedPointsIdxSubset: "allIdx" | "subset1" | "subset2";

  constructor(config: PoseTrackerConfig) {
    this.m_config = config;

    const assetsDir =
      this.m_config.assetsDir ||
      "https://vmcore-test.luxottica.com/headtracker/mp-tf/0.0.0/";

    const facemeshConfig = {
      locateFile: (file: string) => {
        return `${assetsDir}/${file}`;
      },
    };

    this.m_usePosePnP = true;
    this.m_verticalFovDeg = this.m_config.verticalFovDeg;

    const rulerConfig = extractRulerConfig(config.smartRulerConfig);
    if (rulerConfig) {
      this.m_ruler = new Ruler(rulerConfig);
      this.m_ruler2 = new Ruler(rulerConfig);
    }
    if (config.smartRulerConfig) {
      this.m_smartRuler = new SmartRuler(config.smartRulerConfig);
    }

    // MP face mesh options
    this.m_facemesh_options = {
      selfieMode: false,
      enableFaceGeometry: !this.m_usePosePnP,
      maxNumFaces: 1,
      refineLandmarks: this.m_usePosePnP,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
      cameraNear: 1,
      cameraFar: 10000,
      cameraVerticalFovDegrees: this.m_verticalFovDeg,
    };

    this.m_face_mesh = new FaceMesh(facemeshConfig);
    this.m_face_mesh.setOptions(this.m_facemesh_options);

    this.m_initializedFaceMesh = false;
    this.m_listener = undefined;
    this.usePoseSmoothing = true;
    this.useLandmarksSmoothing = true;

    this.m_poseSmoother = new PoseSmoothingCalculator(
      {
        /* oneEuroFilter: {
          frequency: 1,
          minCutOff: 0.01,
          beta: 6,
          derivateCutOff: 1,
          thresholdCutOff: undefined,
          thresholdBeta: undefined,
        }, */
        velocityFilter: {
          windowSize: 5,
          velocityScale: 0.3,
        },
        /* kalmanFilter: {
          C: 1,
          varQ: -1,
          varR: 0.5,
        }, */
      },
      {
        // rotation filter got pretty aggressive parameters, the numbers that it crunches are small
        // small numbers requires aggressive filters
        /* oneEuroFilter: {
          frequency: 1,
          minCutOff: 0.02,
          beta: 10,
          derivateCutOff: 1,
          thresholdCutOff: undefined,
          thresholdBeta: undefined,
        }, */
        velocityFilter: {
          windowSize: 5,
          velocityScale: 0.6,
        },
        /* kalmanFilter: {
          C: 1,
          varQ: -1.5,
          varR: 0.5,
        }, */
      },
      25
    );

    this.enableProminentSubjectSegmenter = false;
    this.m_initializedProminentSubject = false;

    this.m_landmarksSmoother = new LandmarksSmoothingCalculator({
      windowSize: 15,
      velocityScale: 0.05,
    });

    this.m_fps = config.fps;
    this.m_timestampMicroseconds = 0;

    this.selectedPointsIdxSubset = "subset1";
  }

  set velocityFilter(config: VelocityFilterConfig) {
    this.m_landmarksSmoother = new LandmarksSmoothingCalculator(config);
  }

  async initialize() {
    /*
    This function should be called at each "send*" request.
    It will only run initialization if not already done.
    It allows to initialize the prominent subject segmenter only if required, to avoid loading
    unecessary resources.
    Don't inizialize modules in parallel, or the initialization fails
    as described in https://github.com/google/mediapipe/issues/2823#issuecomment-994140622
    */
    if (!this.m_initializedFaceMesh) {
      await this.m_face_mesh.initialize();
      this.m_initializedFaceMesh = true;
    }
    if (
      this.enableProminentSubjectSegmenter &&
      !this.m_initializedProminentSubject
    ) {
      if (this.m_prominentSubjectSegmenter === undefined) {
        this.m_prominentSubjectSegmenter = new ProminentSubjectSegmenter(
          this.m_config.assetsDir
        );
      }
      await this.m_prominentSubjectSegmenter.initialize();
      this.m_initializedProminentSubject = true;
    }
  }

  get usePosePnP(): boolean {
    return this.m_usePosePnP;
  }

  set usePosePnP(enabled: boolean) {
    /*
    NB:
    enableFaceGeometry is not compatible with refineLandmarks, because MediaPipe internal code
    expect to have an exact number of landmarks in order to comute face pose, and instead crash
    if it find the other landmarks that are provided with refineLandmarks.
    For some face regions and for PD/FB size, we need refineLandmarks to be true, therefore they
    will be available only if usePosePnP is True.
    */
    this.m_usePosePnP = enabled;
    this.m_facemesh_options.enableFaceGeometry = !this.m_usePosePnP;
    this.m_facemesh_options.refineLandmarks = this.m_usePosePnP;
    this.m_face_mesh.setOptions(this.m_facemesh_options);
  }

  get verticalFovDeg(): number {
    return this.m_verticalFovDeg;
  }

  set verticalFovDeg(fov: number) {
    this.m_verticalFovDeg = fov;
    if (!this.m_usePosePnP) {
      // It's not necessary to update facemesh options for pose estimation if it's not enabled
      this.m_facemesh_options.cameraVerticalFovDegrees = fov;
      this.m_face_mesh.setOptions(this.m_facemesh_options);
    }
  }

  get minDetectionConfidence(): number | undefined {
    return this.m_facemesh_options.minDetectionConfidence;
  }

  set minDetectionConfidence(condifence: number | undefined) {
    this.m_facemesh_options.minDetectionConfidence = condifence;
    this.m_face_mesh.setOptions(this.m_facemesh_options);
  }

  get minTrackingConfidence(): number | undefined {
    return this.m_facemesh_options.minTrackingConfidence;
  }

  get selfieMode(): boolean | undefined {
    return this.m_facemesh_options.selfieMode;
  }

  set selfieMode(mirror: boolean | undefined) {
    this.m_facemesh_options.selfieMode = mirror;
    this.m_face_mesh.setOptions(this.m_facemesh_options);
  }

  get translationFilter(): PoseSmoothingFilterConfig {
    return this.m_poseSmoother.translationConfig;
  }

  set translationFilter(config: PoseSmoothingFilterConfig) {
    this.m_poseSmoother.translationConfig = config;
  }

  get rotationFilter(): PoseSmoothingFilterConfig {
    return this.m_poseSmoother.rotationConfig;
  }

  set rotationFilter(config: PoseSmoothingFilterConfig) {
    this.m_poseSmoother.rotationConfig = config;
  }

  get attachAngleDeg(): number {
    return this.m_poseSmoother.attachAngleDeg;
  }

  set attachAngleDeg(v: number) {
    this.m_poseSmoother.attachAngleDeg = v;
  }

  initRuler(config?: SmartRulerConfig) {
    const rConfig = extractRulerConfig(config);
    if (this.m_ruler && this.m_ruler2) {
      this.m_ruler.init(rConfig);
      this.m_ruler2.init(rConfig);
    } else {
      this.m_ruler = new Ruler(rConfig);
      this.m_ruler2 = new Ruler(rConfig);
    }

    if (this.m_smartRuler) {
      this.m_smartRuler.init(config);
    } else {
      this.m_smartRuler = new SmartRuler(config);
    }
  }

  set fps(value: number | undefined) {
    this.m_fps = value;
    this.m_timestampMicroseconds = 0;
    this.m_landmarksSmoother.reset();
    this.m_poseSmoother.reset();
  }

  get fps(): number | undefined {
    return this.m_fps;
  }

  close(): void {
    this.m_face_mesh.close();
    this.m_prominentSubjectSegmenter?.close();
  }

  async send(frame: InputMap): Promise<void> {
    await this.sendAndReturnResults(frame);
  }

  reset(): void {
    this.m_face_mesh.reset();
    this.m_prominentSubjectSegmenter?.reset();
    this.m_ruler?.reset();
    this.m_ruler2?.reset();
    this.m_smartRuler?.reset();
    this.m_landmarksSmoother.reset();
    this.m_poseSmoother.reset();
  }

  onResults(listener: PoseTrackerResultsListener): void {
    this.m_listener = listener;
  }

  async sendAndReturnResults(frame: InputMap): Promise<PoseTrackerResult> {
    // This method effectively do the send and combine all results
    await this.initialize();

    // const lastTimestamp = this.m_timestampMicroseconds;
    if (this.m_fps === undefined) {
      this.m_timestampMicroseconds = Date.now() * MILLISECOND_TO_MICRO_SECONDS;
    } else {
      this.m_timestampMicroseconds += SECOND_TO_MICRO_SECONDS / this.m_fps;
    }

    // const diffMicroseconds = this.m_timestampMicroseconds - lastTimestamp;
    // console.log("Diff us:", diffMicroseconds);
    // console.log("FPS:", SECOND_TO_MICRO_SECONDS / diffMicroseconds);

    const faceMeshPromise = this.sendAndReturnPoseTrackerResults(frame);
    const prominentSubjectPromise = this.enableProminentSubjectSegmenter
      ? this.m_prominentSubjectSegmenter?.sendAndReturnResults(frame)
      : undefined;

    const [faceMeshResult, prominentSubjectResult] = await Promise.all([
      faceMeshPromise,
      prominentSubjectPromise,
    ]);

    return this.composeResults(faceMeshResult, prominentSubjectResult);
  }

  private async sendAndReturnPoseTrackerResults(
    frame: InputMap
  ): Promise<Results> {
    window.stats?.faceMesh?.begin();

    return new Promise<Results>((resolve) => {
      this.m_face_mesh.onResults((results) => {
        window.stats?.faceMesh?.end();
        resolve(results);
      });
      this.m_face_mesh.send(frame);
    });
  }

  startRuler(config?: SmartRulerConfig) {
    if (config) {
      this.initRuler(config);
    }
    this.m_ruler?.start();
    this.m_ruler2?.start();
    this.m_smartRuler?.start();
  }

  private composeResults(
    facemeshResults: Results,
    prominentSubjectResults?: ProminentSubjectSegmenterResult
  ): PoseTrackerResult {
    const posetrackerResults: PoseTrackerResult = {
      image: facemeshResults.image,
      hasFace: false,
      segmentationMask: prominentSubjectResults?.segmentationMask,
    };
    const { stats } = window;

    if (facemeshResults.multiFaceLandmarks.length > 0) {
      posetrackerResults.hasFace = true;
      posetrackerResults.debugInfo = {};

      // There is a detected face!
      console.assert(
        facemeshResults.multiFaceLandmarks.length > 0,
        facemeshResults
      );

      const originalLandmarks = facemeshResults.multiFaceLandmarks[0];

      const imageSize: ISize = {
        width: posetrackerResults.image.width,
        height: posetrackerResults.image.height,
      };

      let landmarks: NormalizedLandmarkList;
      if (this.useLandmarksSmoothing) {
        // Smooth 2D landmarks
        landmarks = this.m_landmarksSmoother.apply(
          originalLandmarks,
          imageSize,
          this.m_timestampMicroseconds
        );
      } else {
        landmarks = originalLandmarks;
      }

      posetrackerResults.debugInfo.multiFaceLandmarks =
        facemeshResults.multiFaceLandmarks;
      posetrackerResults.debugInfo.multiFaceGeometry =
        facemeshResults.multiFaceGeometry;
      posetrackerResults.debugInfo.facePoints = convertedToPointList(landmarks);

      // Create pose matrix with "pnp" or "mp" method according to flag "m_usePosePnP"
      if (this.m_usePosePnP) {
        stats?.PnP?.begin();

        const pointsIdxToUse = mpPointsIdx[this.selectedPointsIdxSubset];
        const landmarksSubset = pointsIdxToUse.map((i) => landmarks[i]);
        posetrackerResults.debugInfo.facePointsUsedForPose =
          convertedToPointList(landmarksSubset);

        posetrackerResults.debugInfo.mpPoseMatrix = getPnpPose(
          landmarks,
          imageSize,
          this.verticalFovDeg,
          pointsIdxToUse
        );
        stats?.PnP?.end();
      } else if (facemeshResults.multiFaceGeometry.length > 0) {
        posetrackerResults.debugInfo.mpPoseMatrix = matrixDataToMatrix(
          facemeshResults.multiFaceGeometry[0].getPoseTransformMatrix()
        );
      } else {
        // Just to shut up warnings, should never get here
        posetrackerResults.hasFace = false;
        posetrackerResults.debugInfo.mpPoseMatrix = [
          [1, 0, 0, 0],
          [0, 1, 0, 0],
          [0, 0, 1, 0],
          [0, 0, 0, 1],
        ];
      }

      stats?.poseConvertFilter?.begin();
      posetrackerResults.debugInfo.vtoPoseMatrix = convertMpPoseToVtoPose(
        posetrackerResults.debugInfo.mpPoseMatrix
      );

      if (this.usePoseSmoothing) {
        const rawPoseMatrix = posetrackerResults.debugInfo.vtoPoseMatrix;

        posetrackerResults.debugInfo.vtoPoseMatrix = this.m_poseSmoother.apply(
          rawPoseMatrix,
          this.m_timestampMicroseconds
        );
      }

      posetrackerResults.vtoPoseArray = convertVtoPoseMatrixToVtoPoseArray(
        posetrackerResults.debugInfo.vtoPoseMatrix
      );
      stats?.poseConvertFilter?.end();

      stats?.faceRegion?.begin();
      // Pupils
      if (this.m_facemesh_options.refineLandmarks) {
        /*
        NB: we need face_mesh.refineLandmarks to be true in order to find pupils and therefore to find
        the PD and FB, that are based on iris size. Therefore this is available only when usePosePnP
        is true, because in that way we can disable face_mesh.enableFaceGeometry, that is not
        compatible with face_mesh.refineLandmarks.
        */
        posetrackerResults.leftPupil = createPupilRegion(
          landmarks,
          imageSize,
          LEFT_IRIS_INDICES
        );
        posetrackerResults.rightPupil = createPupilRegion(
          landmarks,
          imageSize,
          RIGHT_IRIS_INDICES
        );

        if (posetrackerResults.leftPupil && posetrackerResults.rightPupil) {
          // Get face landmarks for FB
          const pointsFb = scaledPointList(
            convertedToPointList(landmarks, FB_INDICES),
            imageSize
          );
          const fbLeft = pointsFb[0];
          const fbRight = pointsFb[1];
          // PD and FB
          this.m_ruler?.addSample(
            posetrackerResults.leftPupil,
            posetrackerResults.rightPupil,
            fbLeft,
            fbRight
          );

          // Get face landmarks for FB
          const pointsFb2 = scaledPointList(
            convertedToPointList(landmarks, FB_INDICES2),
            imageSize
          );
          const fbLeft2 = pointsFb2[0];
          const fbRight2 = pointsFb2[1];
          this.m_ruler2?.addSample(
            posetrackerResults.leftPupil,
            posetrackerResults.rightPupil,
            fbLeft2,
            fbRight2
          );

          // Smart Ruler
          this.m_smartRuler?.addSample(
            posetrackerResults.debugInfo.vtoPoseMatrix,
            posetrackerResults.leftPupil,
            posetrackerResults.rightPupil,
            fbLeft,
            fbRight
          );

          posetrackerResults.metrics = {
            currentPDmm: this.m_ruler?.lastPdMm,
            currentFBmm: this.m_ruler?.lastFdMm,
            currentFBmm2: this.m_ruler2?.lastFdMm,

            progress: this.m_ruler?.progress ?? 0.0,
            rulerStatus: this.m_ruler?.status ?? RulerStatus.NOT_INITIALIZED,

            finalPDmm: this.m_ruler?.finalPdMm,
            finalFBmm: this.m_ruler?.finalFbMm,
            finalFBmm2: this.m_ruler2?.finalFbMm,

            outlierProgress: this.m_smartRuler?.progress ?? 0.0,
            outlierRulerStatus:
              this.m_smartRuler?.status ?? RulerStatus.NOT_INITIALIZED,
            outlierPDmm: this.m_smartRuler?.finalPdMm,
            outlierFBmm: this.m_smartRuler?.finalFbMm,
          };
          posetrackerResults.debugInfo.leftFbPoint = fbLeft;
          posetrackerResults.debugInfo.rightFbPoint = fbRight;
          posetrackerResults.debugInfo.leftFbPoint2 = fbLeft2;
          posetrackerResults.debugInfo.rightFbPoint2 = fbRight2;

          posetrackerResults.outlier =
            this.m_smartRuler?.lastOutlierDetectionResult;
        }
      }

      // Eyebrows
      posetrackerResults.leftUpperEyebrow = createFaceRegion(
        landmarks,
        imageSize,
        LEFT_UPPER_EYEBROW_INDICES
      );
      posetrackerResults.leftLowerEyebrow = createFaceRegion(
        landmarks,
        imageSize,
        LEFT_LOWER_EYEBROW_INDICES
      );
      posetrackerResults.rightUpperEyebrow = createFaceRegion(
        landmarks,
        imageSize,
        RIGHT_UPPER_EYEBROW_INDICES
      );
      posetrackerResults.rightLowerEyebrow = createFaceRegion(
        landmarks,
        imageSize,
        RIGHT_LOWER_EYEBROW_INDICES
      );

      // Lips
      posetrackerResults.upperOuterLips = createFaceRegion(
        landmarks,
        imageSize,
        UPPER_OUTER_LIPS_INDICES
      );
      posetrackerResults.upperInnerLips = createFaceRegion(
        landmarks,
        imageSize,
        UPPER_INNER_LIPS_INDICES
      );
      posetrackerResults.lowerInnerLips = createFaceRegion(
        landmarks,
        imageSize,
        LOWER_INNER_LIPS_INDICES
      );
      posetrackerResults.lowerOuterLips = createFaceRegion(
        landmarks,
        imageSize,
        LOWER_OUTER_LIPS_INDICES
      );
      stats?.faceRegion?.end();
    } else {
      if (this.useLandmarksSmoothing) {
        this.m_landmarksSmoother.reset();
      }
      if (this.usePoseSmoothing) {
        this.m_poseSmoother.reset();
      }
    }

    if (this.m_listener !== undefined) {
      this.m_listener(posetrackerResults);
    }

    return posetrackerResults;
  }

  private toDict() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d = new Map<string, any>();
    d.set("usePoseSmoothing", this.usePoseSmoothing);
    d.set("useLandmarksSmoothing", this.useLandmarksSmoothing);
    d.set(
      "enableProminentSubjectSegmenter",
      this.enableProminentSubjectSegmenter
    );
    d.set("usePosePnP", this.m_usePosePnP);
    d.set("verticalFovDeg", this.m_verticalFovDeg);
    d.set("fps", this.m_fps);
    d.set("selectedPointsIdxSubset", this.selectedPointsIdxSubset);

    d.set("config", poseTrackerConfigToDict(this.m_config));
    d.set("facemesh_options", faceMeshOptionsToDict(this.m_facemesh_options));
    d.set("poseSmoother", this.m_poseSmoother.toDict());
    d.set("smartRuler", this.m_smartRuler?.toDict());
    d.set("ruler", this.m_ruler?.toDict());
    d.set("ruler2", this.m_ruler2?.toDict());
    d.set("landmarksSmoother", this.m_landmarksSmoother.toDict());

    return d;
  }

  debugGetConfig() {
    const m = this.toDict();
    const o = mapToObject(m);
    return o;
  }
}
