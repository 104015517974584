import { CircularBuffer } from "./circularBuffer";
import {
  OutlierDetector,
  type OutlierDetectorConfig,
  type OutlierDetectorSample,
  type OutlierDetectorResult,
} from "./outlierDetector";
import { IPoint } from "./types";
import { StaticBuffer } from "./staticBuffer";
import {
  computeIrisPd,
  DEFAULT_IRIS_DIAMETER_MM,
  fbFromPd,
  IPupil,
  RulerStatus,
} from "./faceRegions";

export type SmartRulerConfig = {
  irisDiameterMM?: number;
  maxValidSamples?: number;
  minValidSamples?: number;
  outlierDetectorConfig: OutlierDetectorConfig;
};

export class SmartRuler {
  private initialized: boolean;

  private acceptsSamples: boolean;

  // buffer containing samples classified as OK
  private bufferGoodPD?: StaticBuffer;

  // buffer containing samples classified as OK
  private bufferGoodFB?: StaticBuffer;

  // buffer containing last N samples, used for fallback
  private bufferFallbackPD?: CircularBuffer;

  // buffer containing last N samples, used for fallback
  private bufferFallbackFB?: CircularBuffer;

  private irisDiameterMM: number;

  // Stop after maxValidSamples classified as OK
  private maxValidSamples: number;

  // Fallback if there are not at least minValidSamples classified as OK
  private minValidSamples: number;

  private lastSamplePD?: number;

  private lastSampleFB?: number;

  private outlierDetector?: OutlierDetector;

  private lastResult?: OutlierDetectorResult;

  constructor(config?: SmartRulerConfig) {
    this.initialized = false;
    this.acceptsSamples = false;

    // Set defaults
    this.irisDiameterMM = DEFAULT_IRIS_DIAMETER_MM;
    this.maxValidSamples = 30;
    this.minValidSamples = 5;
    this.lastSamplePD = undefined;
    this.lastSampleFB = undefined;

    this.init(config);
  }

  async init(config?: SmartRulerConfig) {
    this.initialized = false;
    if (!config) {
      return;
    }

    if (config.irisDiameterMM) {
      this.irisDiameterMM = config.irisDiameterMM;
    }

    const irisDiameter = config.irisDiameterMM ?? this.irisDiameterMM;
    const historySize = config.maxValidSamples ?? this.maxValidSamples;

    const minValidSamples = config.minValidSamples ?? 1;
    this.minValidSamples = Math.min(minValidSamples, historySize);

    this.irisDiameterMM = irisDiameter;
    this.bufferGoodPD = new StaticBuffer(historySize);
    this.bufferGoodFB = new StaticBuffer(historySize);
    this.bufferFallbackPD = new CircularBuffer(historySize);
    this.bufferFallbackFB = new CircularBuffer(historySize);

    this.outlierDetector = new OutlierDetector(config.outlierDetectorConfig);

    this.reset();

    this.initialized = true;
    this.acceptsSamples = false;
  }

  private acceptsBufferSamples(): boolean {
    return this.status === RulerStatus.RUNNING;
  }

  reset() {
    this.bufferGoodPD?.reset();
    this.bufferGoodFB?.reset();
    this.bufferFallbackPD?.reset();
    this.bufferFallbackFB?.reset();
    this.outlierDetector?.reset();
    this.lastSamplePD = undefined;
    this.lastSampleFB = undefined;
    this.lastResult = undefined;
    this.acceptsSamples = false;
  }

  start() {
    this.reset();
    // Accepts samples only if it's already initialized
    this.acceptsSamples = this.initialized;
  }

  get lastPdMm() {
    if (this.status === RulerStatus.RUNNING) {
      return this.lastSamplePD;
    }
    return undefined;
  }

  get lastFdMm() {
    if (this.status === RulerStatus.RUNNING) {
      return this.lastSampleFB;
    }
    return undefined;
  }

  get lastOutlierDetectionResult() {
    return this.lastResult;
  }

  get finalPdMm() {
    if (this.status === RulerStatus.COMPLETED) {
      if (this.bufferGoodPD) {
        return this.bufferGoodPD.median();
      }
    } else if (this.status === RulerStatus.RUNNING) {
      if (this.bufferGoodPD && this.bufferFallbackPD) {
        if (this.bufferGoodPD.nSamples < this.minValidSamples) {
          // fallback
          return this.bufferFallbackPD.median();
        }
        return this.bufferGoodPD.median();
      }
    }
    return undefined;
  }

  get finalFbMm() {
    if (this.status === RulerStatus.COMPLETED) {
      if (this.bufferGoodFB) {
        return this.bufferGoodFB.median();
      }
    } else if (this.status === RulerStatus.RUNNING) {
      if (this.bufferGoodFB && this.bufferFallbackFB) {
        if (this.bufferGoodFB.nSamples < this.minValidSamples) {
          // fallback
          return this.bufferFallbackFB.median();
        }
        return this.bufferGoodFB.median();
      }
    }
    return undefined;
  }

  get progress() {
    if (!this.bufferGoodPD) {
      return 0;
    }
    const p = this.bufferGoodPD.nSamples / this.bufferGoodPD.size;
    return p;
  }

  get status() {
    if (!this.initialized) {
      return RulerStatus.NOT_INITIALIZED;
    }
    if (this.acceptsSamples) {
      if (this.bufferGoodPD?.isFilled()) {
        return RulerStatus.COMPLETED;
      }
      return RulerStatus.RUNNING;
    }
    return RulerStatus.STOPPED;
  }

  async addSample(
    pose: number[][],
    leftPupil?: IPupil,
    rightPupil?: IPupil,
    leftFbPoint?: IPoint,
    rightFbPoint?: IPoint
  ) {
    if (!this.acceptsBufferSamples()) {
      return;
    }

    if (leftPupil && rightPupil && leftFbPoint && rightFbPoint) {
      // Compute PD
      const pdMm = computeIrisPd(
        leftPupil.center,
        leftPupil.radius,
        rightPupil.center,
        rightPupil.radius,
        this.irisDiameterMM
      );

      // Compute FB
      const fbMm = fbFromPd(
        leftPupil.center,
        rightPupil.center,
        leftFbPoint,
        rightFbPoint,
        pdMm
      );

      this.lastSamplePD = pdMm;
      this.lastSampleFB = fbMm;

      this.bufferFallbackPD?.push(pdMm);
      this.bufferFallbackFB?.push(fbMm);

      const odSample: OutlierDetectorSample = {
        pose,
        leftPupil,
        rightPupil,
      };
      this.lastResult = this.outlierDetector?.addSample(odSample);
      if (this.lastResult) {
        if (this.lastResult.good) {
          this.bufferGoodPD?.push(pdMm);
          this.bufferGoodFB?.push(fbMm);
        }
      }
    }
  }

  toDict() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d = new Map<string, any>();
    d.set("irisDiameterMM", this.irisDiameterMM);
    d.set("maxValidSamples", this.maxValidSamples);
    d.set("minValidSamples", this.minValidSamples);
    d.set("outlierDetector", this.outlierDetector?.toDict());
    return d;
  }
}
