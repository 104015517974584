export function assert(
  condition: unknown,
  message = "Assert failed"
): asserts condition {
  if (!condition) throw new Error(message);
}

export function radians(deg: number) {
  return (deg * Math.PI) / 180.0;
}

export function degrees(rad: number) {
  return (rad * 180.0) / Math.PI;
}
