import { IPupil } from "./faceRegions";
import { Matrix4 } from "./3rdparty/threejs-math/Matrix4";
import { Quaternion } from "./3rdparty/threejs-math/Quaternion";
import { assert, radians } from "./utils";
import { Vector3 } from "./3rdparty/threejs-math/Vector3";
import { Euler } from "./3rdparty/threejs-math/Euler";

export enum DefectType {
  YAW_TOO_LOW,
  YAW_TOO_HIGH,
  PITCH_TOO_LOW,
  PITCH_TOO_HIGH,
  PUPIL_TOO_SMALL,
}

export type Defect = {
  type: DefectType;
  presence: boolean;
  severity: number; // severity?
};

export type OutlierDetectorResult = {
  good: boolean;
  defects: Defect[];
};

export type OutlierDetectorConfig = {
  yawLimitDeg: number;
  pitchLimitDeg: number;
  pupilMinPx: number;

  yawMaxDeg?: number;
  pitchMaxDeg?: number;
};

export type OutlierDetectorSample = {
  pose: number[][];
  leftPupil: IPupil;
  rightPupil: IPupil;
};

// Compute the "severity" for angles that exceed the limit
// The result is normalized by the max allowed angle
// The result is in range [0, 1]
function angleSeverity(
  currAngle: number,
  limitAngle: number,
  maxAngle: number
) {
  const overLimitBy = Math.max(0, Math.abs(currAngle) - Math.abs(limitAngle));
  const limitToMax = Math.abs(maxAngle) - Math.abs(limitAngle);
  if (limitToMax > 0) {
    return Math.min(1, overLimitBy / limitToMax);
  }
  return 1;
}

// Compute the "severity" for scalars wrt a minimum value
// The result is in range [0, 1]
function scalarMinSeverity(currValue: number, minValue: number) {
  if (minValue > 0) {
    return 1 - Math.min(1, currValue / minValue);
  }
  return 1;
}

export class OutlierDetector {
  private yawLimitRad = 0;

  private pitchLimitRad = 0;

  private pupilMinPx = 0;

  private yawMaxValue = 0;

  private pitchMaxValue = 0;

  constructor(config: OutlierDetectorConfig) {
    this.init(config);
  }

  // eslint-disable-next-line class-methods-use-this
  reset() {
    // do nothing
  }

  init(config: OutlierDetectorConfig) {
    this.pitchLimitRad = (Math.abs(config.pitchLimitDeg) * Math.PI) / 180.0;
    this.yawLimitRad = (Math.abs(config.yawLimitDeg) * Math.PI) / 180.0;
    this.pupilMinPx = config.pupilMinPx;

    this.pitchMaxValue =
      config.pitchMaxDeg !== undefined
        ? radians(config.pitchMaxDeg)
        : radians(45);

    this.yawMaxValue =
      config.yawMaxDeg !== undefined ? radians(config.yawMaxDeg) : radians(45);
  }

  addSample(sample: OutlierDetectorSample): OutlierDetectorResult {
    const poseMatrix = sample.pose;
    const m = new Matrix4();
    m.set(
      /* eslint-disable prettier/prettier */
      poseMatrix[0][0], poseMatrix[0][1], poseMatrix[0][2], poseMatrix[0][3], 
      poseMatrix[1][0], poseMatrix[1][1], poseMatrix[1][2], poseMatrix[1][3], 
      poseMatrix[2][0], poseMatrix[2][1], poseMatrix[2][2], poseMatrix[2][3], 
      poseMatrix[3][0], poseMatrix[3][1], poseMatrix[3][2], poseMatrix[3][3]
      /* eslint-enable prettier/prettier */
    );
    const p = new Vector3();
    const q = new Quaternion();
    const s = new Vector3();
    m.decompose(p, q, s);
    const e = new Euler();
    e.setFromQuaternion(q, "XYZ");

    let good = true;
    const defects: Defect[] = [
      {
        type: DefectType.YAW_TOO_LOW,
        presence: false,
        severity: 0,
      },
      {
        type: DefectType.YAW_TOO_HIGH,
        presence: false,
        severity: 0,
      },
      {
        type: DefectType.PITCH_TOO_LOW,
        presence: false,
        severity: 0,
      },
      {
        type: DefectType.PITCH_TOO_HIGH,
        presence: false,
        severity: 0,
      },
      {
        type: DefectType.PUPIL_TOO_SMALL,
        presence: false,
        severity: 0,
      },
    ];

    // Verify yaw
    const yawRad = e.y;
    if (yawRad < -this.yawLimitRad) {
      assert(defects[DefectType.YAW_TOO_LOW].type === DefectType.YAW_TOO_LOW);
      defects[DefectType.YAW_TOO_LOW].presence = true;
      defects[DefectType.YAW_TOO_LOW].severity = angleSeverity(
        yawRad,
        this.yawLimitRad,
        this.yawMaxValue
      );
      good = false;
    }

    if (yawRad > this.yawLimitRad) {
      assert(defects[DefectType.YAW_TOO_HIGH].type === DefectType.YAW_TOO_HIGH);
      defects[DefectType.YAW_TOO_HIGH].presence = true;
      defects[DefectType.YAW_TOO_HIGH].severity = angleSeverity(
        yawRad,
        this.yawLimitRad,
        this.yawMaxValue
      );
      good = false;
    }

    // Verify pitch
    const pitchRad = e.x;
    if (pitchRad < -this.pitchLimitRad) {
      assert(
        defects[DefectType.PITCH_TOO_LOW].type === DefectType.PITCH_TOO_LOW
      );
      defects[DefectType.PITCH_TOO_LOW].presence = true;
      defects[DefectType.PITCH_TOO_LOW].severity = angleSeverity(
        pitchRad,
        this.pitchLimitRad,
        this.pitchMaxValue
      );
      good = false;
    }

    if (pitchRad > this.pitchLimitRad) {
      assert(
        defects[DefectType.PITCH_TOO_HIGH].type === DefectType.PITCH_TOO_HIGH
      );
      defects[DefectType.PITCH_TOO_HIGH].presence = true;
      defects[DefectType.PITCH_TOO_HIGH].severity = angleSeverity(
        pitchRad,
        this.pitchLimitRad,
        this.pitchMaxValue
      );
      good = false;
    }

    // Valid pupils px
    const minPupil = Math.min(
      sample.leftPupil.radius,
      sample.rightPupil.radius
    );
    if (minPupil < this.pupilMinPx) {
      assert(
        defects[DefectType.PUPIL_TOO_SMALL].type === DefectType.PUPIL_TOO_SMALL
      );
      defects[DefectType.PUPIL_TOO_SMALL].presence = true;
      defects[DefectType.PUPIL_TOO_SMALL].severity = scalarMinSeverity(
        minPupil,
        this.pupilMinPx
      );
      good = false;
    }

    return {
      good,
      defects,
    };
  }

  toDict() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d = new Map<string, any>();
    d.set("yawLimitRad", this.yawLimitRad);
    d.set("pitchLimitRad", this.pitchLimitRad);
    d.set("pupilMinPx", this.pupilMinPx);
    d.set("yawMaxValue", this.yawMaxValue);
    d.set("pitchMaxValue", this.pitchMaxValue);
    return d;
  }
}
