import {
  InputMap,
  Results,
  SelfieSegmentation,
  VERSION as mpVersion,
} from "@mediapipe/selfie_segmentation";

export type ProminentSubjectSegmenterResult = Results;

export class ProminentSubjectSegmenter {
  private readonly selfieSegmentation: SelfieSegmentation;

  private listener?: (res: Results) => void = undefined;

  private initialized = false;

  constructor(assetsDir?: string) {
    const actualAssetsDir =
      assetsDir ||
      `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${mpVersion}`;

    const config = {
      locateFile: (file: string) => {
        return `${actualAssetsDir}/${file}`;
      },
    };

    this.selfieSegmentation = new SelfieSegmentation(config);
    this.selfieSegmentation.setOptions({
      modelSelection: 0, // 0: general mode (square input), 1: landscape mode (smaller landscape input)
    });

    // Set SelfieSegmenter main callback
    this.selfieSegmentation.onResults((res) => {
      if (this.listener) {
        this.listener(res);
      }
    });
  }

  async initialize(): Promise<void> {
    await this.selfieSegmentation.initialize();
    this.initialized = true;
  }

  reset(): void {
    return this.selfieSegmentation.reset();
  }

  close(): Promise<void> {
    return this.selfieSegmentation.close();
  }

  async send(frame: InputMap): Promise<void> {
    if (!this.initialized) {
      await this.initialize();
    }
    return this.selfieSegmentation.send(frame);
  }

  onResults(listener: (res: Results) => void): void {
    this.listener = listener;
  }

  async sendAndReturnResults(
    frame: InputMap
  ): Promise<ProminentSubjectSegmenterResult> {
    window.stats?.prominentSegm?.begin();

    return new Promise<ProminentSubjectSegmenterResult>((resolve) => {
      this.onResults((results) => {
        window.stats?.prominentSegm?.end();
        resolve(results);
      });
      this.send(frame);
    });
  }
}

export default ProminentSubjectSegmenter;
