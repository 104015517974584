import { NormalizedLandmarkList } from "@mediapipe/face_mesh";
import * as math from "mathjs";
import { IPoint, IPointList, ISize } from "./types";
import { convertedToPointList, scaledPointList, pointsDistance } from "./point";

export interface IPupil {
  center: IPoint;
  radius: number;
  points: IPointList;
}

export enum RulerStatus {
  NOT_INITIALIZED,
  STOPPED,
  RUNNING,
  COMPLETED,
}

export interface IFaceMetrics {
  currentPDmm?: number;
  currentFBmm?: number;
  currentFBmm2?: number;

  progress: number;
  rulerStatus: RulerStatus;
  finalPDmm?: number;
  finalFBmm?: number;
  finalFBmm2?: number;

  outlierProgress: number;
  outlierRulerStatus: RulerStatus;
  outlierPDmm?: number;
  outlierFBmm?: number;
}

export const LEFT_IRIS_INDICES = [473, 474, 475, 476, 477];
export const RIGHT_IRIS_INDICES = [468, 469, 470, 471, 472];

export const LEFT_UPPER_EYEBROW_INDICES = [276, 283, 282, 295, 285];
export const LEFT_LOWER_EYEBROW_INDICES = [300, 293, 334, 296, 336];

export const RIGHT_UPPER_EYEBROW_INDICES = [46, 53, 52, 65, 55];
export const RIGHT_LOWER_EYEBROW_INDICES = [70, 63, 105, 66, 107];

export const UPPER_OUTER_LIPS_INDICES = [
  61, 185, 40, 39, 37, 0, 267, 269, 270, 409, 291,
]; // 11 landmarks
export const UPPER_INNER_LIPS_INDICES = [
  78, 191, 80, 81, 82, 13, 312, 311, 310, 415, 308,
]; // 11 landmarks
export const LOWER_INNER_LIPS_INDICES = [
  78, 95, 88, 178, 87, 14, 317, 402, 318, 324, 308,
]; // 11 landmarks
export const LOWER_OUTER_LIPS_INDICES = [
  61, 146, 91, 181, 84, 17, 314, 405, 321, 375, 291,
]; // 11 landmarks

export const DEFAULT_IRIS_DIAMETER_MM = 12.2;
export const FB_INDICES = [21, 251];
export const FB_INDICES2 = [139, 368];

export function createFaceRegion(
  landmarks: NormalizedLandmarkList,
  imageSize: ISize,
  indices: number[]
): IPointList | undefined {
  if (landmarks.length === 0) {
    return undefined;
  }
  if (indices.length === 0) {
    return undefined;
  }
  const maxIndex = Math.max(...indices);
  if (maxIndex >= landmarks.length) {
    return undefined;
  }

  const region = convertedToPointList(landmarks, indices);
  return scaledPointList(region, imageSize);
}

// https://github.com/luxdeepblue/vto-projects/blob/740d1f3442055969014b4e3d31785b8487251468/modules/pupil_detection/src/pupil_detection_utils.cpp#L395
export function createPupilRegion(
  landmarks: NormalizedLandmarkList,
  imageSize: ISize,
  indices: number[]
): IPupil | undefined {
  if (landmarks.length === 0) {
    return undefined;
  }
  if (indices.length !== 5) {
    return undefined;
  }
  const maxIndex = Math.max(...indices);
  if (maxIndex >= landmarks.length) {
    return undefined;
  }

  const irisPoints = convertedToPointList(landmarks, indices);
  const scaledPoints = scaledPointList(irisPoints, imageSize);
  const center = scaledPoints[0];

  const radii = [
    pointsDistance(center, scaledPoints[1]),
    pointsDistance(center, scaledPoints[2]),
    pointsDistance(center, scaledPoints[3]),
    pointsDistance(center, scaledPoints[4]),
  ];

  const radius = math.mean(radii);
  const pupil: IPupil = {
    center,
    radius,
    points: scaledPoints,
  };
  return pupil;
}

// https://github.com/luxdeepblue/vto-projects/blob/740d1f3442055969014b4e3d31785b8487251468/modules/anthropometry/src/pd_detector_iris.cpp

function computeFactorMmPx(
  pupilRadiusPx: number,
  pupilDiameterMm: number
): number {
  const pupilDiameterPx = pupilRadiusPx * 2;
  const factorMmPx = pupilDiameterMm / pupilDiameterPx;
  return factorMmPx;
}

export function computeIrisPd(
  centerLeft: IPoint,
  radiusLeft: number,
  centerRight: IPoint,
  radiusRight: number,
  pupilDiameterMm: number
): number {
  const pupilRadiusPx = (radiusLeft + radiusRight) / 2;
  const KMmPx = computeFactorMmPx(pupilRadiusPx, pupilDiameterMm);
  const pupilDistPx = pointsDistance(centerLeft, centerRight);
  const irisPdMm = pupilDistPx * KMmPx;
  return irisPdMm;
}

// https://github.com/luxdeepblue/vto-projects/blob/740d1f3442055969014b4e3d31785b8487251468/modules/anthropometry/src/anthropometry_utils.cpp#L19
export function fbFromPd(
  pupilCenterLeft: IPoint,
  pupilCenterRight: IPoint,
  faceLeft: IPoint,
  faceRight: IPoint,
  pdMm: number
): number {
  const pdPx = pointsDistance(pupilCenterLeft, pupilCenterRight);
  const fbPx = pointsDistance(faceLeft, faceRight);
  const KMmPx = pdMm / pdPx;
  const fbMm = fbPx * KMmPx;
  return fbMm;
}
