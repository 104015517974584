import { NormalizedLandmarkList } from "@mediapipe/face_mesh";
import { ISize } from "../types";
import {
  KeypointsSmoothingConfig,
  VelocityFilterConfig,
} from "../3rdparty/tfjs-models/shared/calculators/interfaces/config_interfaces";
import { KeypointsSmoothingFilter } from "../3rdparty/tfjs-models/shared/filters/keypoints_smoothing";
import {
  keypointsToNormalizedLandmarkList,
  normalizedLandmarkListToKepoints,
} from "./interop";

export class LandmarksSmoothingCalculator {
  private m_smoother: KeypointsSmoothingFilter;

  private m_config: KeypointsSmoothingConfig;

  constructor(config: VelocityFilterConfig) {
    this.m_config = {
      velocityFilter: config,
    };

    this.m_smoother = new KeypointsSmoothingFilter(this.m_config);
  }

  apply(
    landmarks: NormalizedLandmarkList,
    imageSize: ISize,
    timestamp: number
  ) {
    const keypoints = normalizedLandmarkListToKepoints(landmarks);
    const normalized = true;
    const smoothedKeypoints = this.m_smoother.apply(
      keypoints,
      timestamp,
      imageSize,
      normalized
    );

    return keypointsToNormalizedLandmarkList(smoothedKeypoints);
  }

  reset() {
    this.m_smoother = new KeypointsSmoothingFilter(this.m_config);
  }

  toDict() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d = new Map<string, any>();
    d.set("config", this.m_config);
    return d;
  }
}
