import { IPoint } from "./types";
import { StaticBuffer } from "./staticBuffer";
import {
  computeIrisPd,
  DEFAULT_IRIS_DIAMETER_MM,
  fbFromPd,
  IPupil,
  RulerStatus,
} from "./faceRegions";

export type RulerConfig = {
  irisDiameterMM?: number;
  historySize?: number;
};

export class Ruler {
  private m_initialized: boolean;

  private m_acceptsSamples: boolean;

  private m_bufferPD?: StaticBuffer;

  private m_bufferFB?: StaticBuffer;

  private m_irisDiameterMM: number;

  private m_historySize: number;

  private m_lastSamplePD?: number;

  private m_lastSampleFB?: number;

  constructor(config?: RulerConfig) {
    this.m_initialized = false;
    this.m_acceptsSamples = false;

    // Set defaults
    this.m_irisDiameterMM = DEFAULT_IRIS_DIAMETER_MM;
    this.m_historySize = 30;
    this.m_lastSamplePD = undefined;
    this.m_lastSampleFB = undefined;

    this.init(config);
  }

  async init(config?: RulerConfig) {
    this.m_initialized = false;
    if (!config) {
      return;
    }

    if (config.irisDiameterMM) {
      this.m_irisDiameterMM = config.irisDiameterMM;
    }

    const irisDiameter = config.irisDiameterMM ?? this.m_irisDiameterMM;
    const historySize = config.historySize ?? this.m_historySize;

    this.m_irisDiameterMM = irisDiameter;
    this.m_bufferPD = new StaticBuffer(historySize);
    this.m_bufferFB = new StaticBuffer(historySize);

    this.reset();

    this.m_initialized = true;
    this.m_acceptsSamples = false;
  }

  private acceptsBufferSamples(): boolean {
    return this.status === RulerStatus.RUNNING;
  }

  reset() {
    this.m_bufferPD?.reset();
    this.m_bufferFB?.reset();
    this.m_lastSamplePD = undefined;
    this.m_lastSampleFB = undefined;
    this.m_acceptsSamples = false;
  }

  start() {
    this.reset();
    // Accepts samples only if it's already initialized
    this.m_acceptsSamples = this.m_initialized;
  }

  get lastPdMm() {
    if (this.status === RulerStatus.RUNNING) {
      return this.m_lastSamplePD;
    }
    return undefined;
  }

  get lastFdMm() {
    if (this.status === RulerStatus.RUNNING) {
      return this.m_lastSampleFB;
    }
    return undefined;
  }

  get finalPdMm() {
    if (this.status === RulerStatus.COMPLETED) {
      if (this.m_bufferPD) {
        return this.m_bufferPD.median();
      }
    }
    return undefined;
  }

  get finalFbMm() {
    if (this.status === RulerStatus.COMPLETED) {
      if (this.m_bufferFB) {
        return this.m_bufferFB.median();
      }
    }
    return undefined;
  }

  get progress() {
    if (!this.m_bufferPD) {
      return 0;
    }
    const p = this.m_bufferPD.nSamples / this.m_bufferPD.size;
    return p;
  }

  get status() {
    if (!this.m_initialized) {
      return RulerStatus.NOT_INITIALIZED;
    }
    if (this.m_acceptsSamples) {
      if (this.m_bufferPD?.isFilled()) {
        return RulerStatus.COMPLETED;
      }
      return RulerStatus.RUNNING;
    }
    return RulerStatus.STOPPED;
  }

  async addSample(
    leftPupil?: IPupil,
    rightPupil?: IPupil,
    leftFbPoint?: IPoint,
    rightFbPoint?: IPoint
  ) {
    if (!this.acceptsBufferSamples()) {
      return;
    }

    if (leftPupil && rightPupil && leftFbPoint && rightFbPoint) {
      // Compute PD
      const pdMm = computeIrisPd(
        leftPupil.center,
        leftPupil.radius,
        rightPupil.center,
        rightPupil.radius,
        this.m_irisDiameterMM
      );

      // Compute FB
      const fbMm = fbFromPd(
        leftPupil.center,
        rightPupil.center,
        leftFbPoint,
        rightFbPoint,
        pdMm
      );

      this.m_lastSamplePD = pdMm;
      this.m_lastSampleFB = fbMm;

      this.m_bufferPD?.push(pdMm);
      this.m_bufferFB?.push(fbMm);
    }
  }

  toDict() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d = new Map<string, any>();
    d.set("irisDiameterMM", this.m_irisDiameterMM);
    d.set("historySize", this.m_historySize);
    return d;
  }
}
